import request from '@/utils/request'


// 查询兴趣标签列表
export function listInterestTag(query) {
  return request({
    url: '/platform/interest-tag/list',
    method: 'get',
    params: query
  })
}

// 查询兴趣标签分页
export function pageInterestTag(query) {
  return request({
    url: '/platform/interest-tag/page',
    method: 'get',
    params: query
  })
}

// 查询兴趣标签详细
export function getInterestTag(data) {
  return request({
    url: '/platform/interest-tag/detail',
    method: 'get',
    params: data
  })
}

// 新增兴趣标签
export function addInterestTag(data) {
  return request({
    url: '/platform/interest-tag/add',
    method: 'post',
    data: data
  })
}

// 修改兴趣标签
export function updateInterestTag(data) {
  return request({
    url: '/platform/interest-tag/edit',
    method: 'post',
    data: data
  })
}

// 删除兴趣标签
export function delInterestTag(data) {
  return request({
    url: '/platform/interest-tag/delete',
    method: 'post',
    data: data
  })
}
